@import 'page-include';

.login-page {

    @include mq($dt-tablet-ls) {
        display: flex;
    }
}

.login-box {
    margin-bottom: $padding-xl;

    @include mq($dt-tablet-ls) {
        width: 50%;
        margin: $padding-m $padding-xl $padding-xl;
    }
}

.page-title-container {
    margin: $padding-m $padding-xl;

    @include mq($mobile-tablet-pt) {
        margin: $padding-m 0;
    }
}

.login-title {
    margin: $padding-m 0;

    @include mq($mobile-max) {
        margin: 0 0 $padding-m;
    }
}

.password-remember-forgot {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.password-creation-instructions-text {
    font-size: 12px;
    color: $black;
}

.form__terms {
    @include mq($mobile-tablet-pt) {
        padding: 10px 0;
        line-height: 15px;
    }
}
