/************ Skandinavisk colors ***********/
/******* Aspect ratios *****/
/****** Transition *******/
@media only screen and (min-width: 901px) {
  .login-page {
    display: flex;
  }
}

.login-box {
  margin-bottom: 60px;
}
@media only screen and (min-width: 901px) {
  .login-box {
    width: 50%;
    margin: 30px 60px 60px;
  }
}

.page-title-container {
  margin: 30px 60px;
}
@media only screen and (max-width: 900px) {
  .page-title-container {
    margin: 30px 0;
  }
}

.login-title {
  margin: 30px 0;
}
@media only screen and (max-width: 767px) {
  .login-title {
    margin: 0 0 30px;
  }
}

.password-remember-forgot {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.password-creation-instructions-text {
  font-size: 12px;
  color: #000;
}

@media only screen and (max-width: 900px) {
  .form__terms {
    padding: 10px 0;
    line-height: 15px;
  }
}